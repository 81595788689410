// Always make sure that we don't see a content flicker when creating a new
// a/b test. You can avoid this by adding the css class "async-hide" to the
// html tag. This will hide the page until the page load event is triggered.
// Be aware that this would also increase the LCP (Largest Contentful Paint)
// time for this page. If possible, only hide the part of the page which is
// actually tested.

import storage from "storage"
import AlephBet from "alephbet"
import page_load from "page_load"
import supported_browsers from "supported_browsers"
import {check_goals} from "./goals"
import localized_testimonial_on_registration_page_de_v3 from
  "./localized_testimonial_on_registration_page_de.js"
import localized_testimonial_on_registration_page_es_v3 from
  "./localized_testimonial_on_registration_page_es.js"
import localized_testimonial_on_registration_page_pt_v3 from
  "./localized_testimonial_on_registration_page_pt.js"
import move_terminology_selection_modal from
  "./move_terminology_selection_modal_to_study_units_and_articles.js"

(() => {
  // not running experiment in test mode.
  if (window.gon.javascript_test_env) return

  // not running unless local storage is available
  if (!storage.enabled) return

  // only run the A/B test for supported browsers on production
  if (
    window.gon.rails_env === "production" &&
    !supported_browsers.test(window.navigator.userAgent)
  ) {
    return
  }

  if (window.gon.alephbet_debug) AlephBet.options.debug = true

  return page_load.on_ready(() => {
    localized_testimonial_on_registration_page_de_v3()
    localized_testimonial_on_registration_page_es_v3()
    localized_testimonial_on_registration_page_pt_v3()
    move_terminology_selection_modal()
    check_goals()
  })
})()

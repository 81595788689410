import route_regex from "route_regex"
import ajax from "ajax"
import $ from "cash-dom"

export const logged_in_user = () => window.gon.current_user

export const not_logged_in = () => !logged_in_user()

export const get_user_id = () => logged_in_user()?.user_id

export const has_role = (role) => logged_in_user()?.role === role

export const is_potential_customer = () =>
  not_logged_in() || has_role("user") || has_role("trial")

export function logged_in_potential_customer() {
  return has_role("user") || has_role("trial")
}

export const is_customer = () =>
  has_role("paying_customer") || has_role("non_paying_customer")

export const on_page_with_learn_more_box = () => $(".learn-more-card").length

export const on_quiz_page = () =>
  window.location.pathname.match(route_regex("/quizzes"))

export const on_quiz_start_page = () =>
  // matching quizzes/<something>
  window.location.pathname.match(route_regex("/quizzes/.+"))

export const on_article_page = () =>
  // matching library/<something>/<something>
  window.location.pathname.match(route_regex("/library/.+/.+"))

export const on_study_unit = () =>
  // matching study/<something>
  window.location.pathname.match(route_regex("/study/.+"))

export const on_content_page = () =>
  on_quiz_start_page() ||
  on_video_show_page() ||
  on_article_page()

export const on_content_listing_page = () =>
  window.location.pathname.match(route_regex("/start/.+"))

export const on_any_registration_page = () =>
  window.location.pathname.match(
    route_regex("/(register|registration-ebook)$")
  )

export const on_registration_page = () =>
  window.location.pathname.match(
    route_regex("/(register)$")
  )

export const on_en_page = () => window.location.pathname.match(/^\/en\/?/)

export const on_de_page = () => window.location.pathname.match(/^\/de\/?/)

export const on_es_page = () => window.location.pathname.match(/^\/es\/?/)

export const on_pt_page = () => window.location.pathname.match(/^\/pt\/?/)

export const no_params = () => window.location.search.length <= 0

export const has_params = () => window.location.search.length > 0

export const on_quizzes_root = () =>
  window.location.pathname.match(route_regex("/quizzes/?$")) && no_params()

export const on_atlas_root = () =>
  window.location.pathname.match(route_regex("/atlas/?$")) && no_params()

export const on_videos_root = () =>
  window.location.pathname.match(route_regex("/videos/?$")) && no_params()

export const on_video_page = () =>
  window.location.pathname.match(route_regex("/videos"))

export const on_video_show_page = () =>
  window.location.pathname.match(route_regex("/videos/.+"))

export const on_atlas_term_page = () =>
  window.location.pathname.match(route_regex("/atlas/.+"))

export const on_pricing_page = () =>
  window.location.pathname.match(route_regex("/pricing"))

export const on_checkout_page = () =>
  window.location.pathname.match(route_regex("/checkout"))

export const on_registration_with_topic = () =>
  // matches register with query string
  window.location.pathname.match(
    route_regex("/(register|registration-ebook)*$")
  ) && window.location.search.match("topic")

export const on_homepage = () =>
  window.location.pathname.match(route_regex("?/?$"))

export const on_dashboard = () =>
  window.location.pathname.match(route_regex("/dashboard"))

export const never_trained = () => {
  // we can't reliably tell if someone trained if they are not logged in
  // (even if they did, it might have been very long time ago)
  if (not_logged_in()) return true
  return !(window.gon.current_user != null
    ? window.gon.current_user.already_trained
    : undefined)
}

export const never_watched_video = () => {
  // we can't reliably tell if someone watched a video if they are not
  // logged in (even if they did, it might have been very long time ago)
  if (not_logged_in()) return true
  return (
    (window.gon.current_user != null
      ? window.gon.current_user.videos_played_count
      : undefined) === 0
  )
}

export function experiment_tracking(experiment, variant) {
  if (!window.gon.current_user) return

  const identifier = `${experiment}:${variant}`
  const active_experiments = window.gon.current_user?.experiments || []
  if (active_experiments.includes(identifier)) return

  active_experiments.push(identifier)
  ajax.post("/api/experiments", {experiments: active_experiments})
}

// takes a HTML string and updates the body with it
export const replace_body = (html) => {
  const new_page = document.createElement("html")
  new_page.innerHTML = html
  const body = new_page.querySelector("body")
  const parent = document.body.parentElement
  return parent.replaceChild(body, document.body)
}

// see https://github.com/gingerlime/alephbet/wiki/Tips-&-Caveats#redirects
export const hide_page = () => {
  const style = document.createElement("style")
  style.setAttribute("type", "text/css")
  style.innerHTML = "body{ display:none; visibility:hidden; }"
  document.head.appendChild(style)
  return style
}

export const now = () => Math.floor(Date.now() / 1000)

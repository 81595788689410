import AlephBet from "alephbet"
import {storage_adapter, super_properties_lamed_adapter} from "./adapters"
import {
  on_de_page,
  on_en_page,
  on_study_unit,
  on_article_page,
  logged_in_potential_customer
} from "./helpers"
import {pricing_page_visited} from "./goals"

export default () => {
  const move_terminology_selection_modal = new AlephBet.Experiment({
    name: "202405 Move terminology selection modal to study units and articles",
    tracking_adapter: super_properties_lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => (
      (logged_in_potential_customer() &&
      (on_de_page() || on_en_page()) &&
      (on_study_unit() || on_article_page()))
    ),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Move-modal": {
        activate: () => {
          window.gon.show_terminology_modal_in_study_units_and_articles = true
        }
      }
    }
  })

  move_terminology_selection_modal.add_goals([
    pricing_page_visited
  ])
}
